<template>
  <div class="ParkDetail">
    <div class="banner">
      <img
        src="@/assets/images/parkBanner.png"
        alt=""
      >
    </div>
    <div class="breadBlock">
      <Breadcrumb
        separator=">"
        class="bread"
      >
        <BreadcrumbItem
          v-for="item in $route.query.source"
          @click.native="routeTo(item.path)"
          class="textColorO"
        >{{item.name}}</BreadcrumbItem>
        <BreadcrumbItem class="textColor">园区详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="main container">
      <img v-if="list.url2" :src="list.url2">
      <div class="content">
        <div class="desc">
          <div class="label">陵园简介</div>
          <p v-html="list.cemeteryDesc"></p>
        </div>
        <div>
          <div class="item">
            <div class="label">园区名称</div>
            <p>{{list.cemeteryName || ''}}</p>
          </div>
          <div class="item">
            <div class="label">园区起价</div>
            <p>{{list.cemeteryStartPrice || ''}}</p>
          </div>
        </div>
        <div>
          <div class="item">
            <div class="label">园区特色</div>
            <p>{{list.cemeteryHighlights || ''}}</p>
          </div>
          <div class="item">
            <div class="label">价格说明</div>
            <p>{{list.priceDescription || ''}}</p>
          </div>
        </div>
        <div>
          <div class="item">
            <div class="label">园区类型</div>
            <p>{{list.cemeteryMold || ''}}</p>
          </div>
          <div class="item">
            <div class="label">开盘时间</div>
            <p>{{time}}</p>
          </div>
        </div>
         <div>
          <div class="item">
           <div class="label">占地面积</div>
            <p>{{list.cemeteryAreaMeasure || ''}}</p>
          </div>
          <div class="item">
            <div class="label">园区朝向</div>
            <p>{{list.cemeteryDirection || ''}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/util/api'
import { formatDateTimeT } from '@/util'
export default {
  data() {
    return {
      cemeteryId: this.$route.query.cemeteryId || 0,
      list:{},
      time:'',
    }
  },
  mounted() {
    this.search();
  },
  methods: {
    search(){
      api.companyCemeteryDetail({cemeteryId:this.cemeteryId },res=>{
        console.log(res);
        this.list=res.data;
        if(res.data.cemeteryOpenYear){
          this.time = formatDateTimeT(new Date(res.data.cemeteryOpenYear || 0))
        }else{
          this.time=''
        }
        
      }) 
    }
  },
}
</script>

<style lang="less" scoped>
.ParkDetail {
  .banner {
    height: 220px;
  }
  .breadBlock {
    line-height: 40px;
    cursor: pointer;

    .bread {
      width: 1120px;
      margin: auto;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      letter-spacing: 1px;
      .textColor {
        font-weight: 400;
        color: #299654;
      }
    }
    /deep/.ivu-breadcrumb-item-separator {
      color: #929292;
    }
  }
  .main {
    width: 1120px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 24px;
    img {
      width: 1072px;
      height: 431px;
      border-radius: 4px;
    }
    .content {
      margin-top: 22px;
      overflow: hidden;

      .desc {
        display: flex;
        margin-bottom: 40px;
      }
      .item {
        float: left;
        width: 525px;
        display: flex;
        margin-bottom: 16px;
        overflow: hidden;
        margin-right: 20px;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
      .label {
        width: 78px;
        height: 24px;
        font-size: 18px;
        font-weight: 500;
        color: #4d4d4d;
        line-height: 24px;
        letter-spacing: 1px;
        margin-right: 16px;
        font-weight: 600;
      }

      p {
        flex: 1;
        font-size: 18px;
        font-weight: 400;
        color: #4d4d4d;
        line-height: 27px;
        letter-spacing: 1px;
        text-align: justify;
      }
    }
  }
}
</style>